import React, { useEffect } from "react";
import ReactDOM from "react-dom";
// Router
import track, { useTracking } from "react-tracking";
import { Link, useHistory } from "react-router-dom";
// Axios
import axios from "axios";
import { setAuthTokens } from "axios-jwt";
// Utils
import {
  APIConfig,
  AuthResponseToAuthTokens,
} from "../../services/apiConfiguration.js";
import apiEndpointList from "../../config/core_banking/endpoint.js";
import posthog from "posthog-js";
import { capturePosthogEvent } from "../../utilities/posthogUtils.js";
import * as forge from "node-forge";
// Components
import DecentroBanner from "../../components/Shared/DecentroBanner/DecentroBanner.js";
import SnackbarMessage from "../../components/Shared/SnackbarMessage/SnackbarMessage.js";
import StickyFooter from "../../components/StickyFooter/StickyFooter.js";
import { emailRegexValidate } from "../../utilities/validators/inputValidators";
// Styles
import "./Login.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const Login = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("Login Page View", {
        path: window.location.origin + window.location.pathname,
      });
    }
  }, []);
  const { trackEvent } = useTracking();

  let history = useHistory();
  let label = { light: "Welcome", bold: "Back!" };

  const userData = [
    {
      email: undefined,
      password: undefined,
    },
  ];

  const errors = {
    email: "",
    password: "",
  };

  const requiredFieldErrors = {
    email: "Email is required",
    password: "Password is required",
  };

  const invalidFieldErrors = {
    email: "Please enter valid email",
  };

  const handleChange = (event) => {
    userData[0][event.currentTarget.name] = event.currentTarget.value;
  };

  const validateInputs = () => {
    let valid = true;
    const inputElements = document.querySelectorAll("input");
    for (let i = 0; i < inputElements.length; i++) {
      if (inputElements[i].name == "email") {
        valid = emailValidationSequence(inputElements[i].name, valid);
      } else {
        valid = checkRequired(inputElements[i].name, valid);
      }
    }
    return valid;
  };

  const emailValidationSequence = (key, valid) => {
    if (userData[0][key] === undefined) {
      errors[key] = requiredFieldErrors[key];
      ReactDOM.render(errors[key], document.getElementById(key + "_error"));
      valid = false;
    } else if (!emailRegexValidate(userData[0][key])) {
      errors[key] = invalidFieldErrors[key];
      ReactDOM.render(errors[key], document.getElementById(key + "_error"));
      valid = false;
    } else {
      errors[key] = undefined;
      ReactDOM.unmountComponentAtNode(document.getElementById(key + "_error"));
    }
    return valid;
  };

  const checkRequired = (key, valid) => {
    if (userData[0][key] === undefined) {
      errors[key] = requiredFieldErrors[key];
      ReactDOM.render(errors[key], document.getElementById(key + "_error"));
      valid = false;
    } else {
      errors[key] = undefined;
      ReactDOM.unmountComponentAtNode(document.getElementById(key + "_error"));
    }
    return valid;
  };

  // ^ Get registered Email's
  function getRegisteredEmail() {
    APIConfig.API_Client.post(
      apiEndpointList.ACCOUNTS_REGISTERED_EMAIL_REPORTS.baseUrl +
        apiEndpointList.ACCOUNTS_REGISTERED_EMAIL_REPORTS.endpoint,
      {},
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response.length === 0) {
          localStorage.setItem("reportsDefaultEmail", null);
          localStorage.setItem("commonName", null);
        } else {
          localStorage.setItem(
            "reportsDefaultEmail",
            response?.data[0]?.email_id
          );

          localStorage.setItem("commonName", response?.data[0]?.common_name);
        }
      })
      .catch((error) => {});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      capturePosthogEvent(
        window.location.origin,
        window.location.pathname,
        "cta"
      );
    }
    const publicKey = process.env.REACT_APP_AUTH_ENCRYPTION_PUBLIC_KEY;
    const forgedPublicKey = forge.pki.publicKeyFromPem(publicKey);
    let encryptedPassword = forgedPublicKey.encrypt(
      userData[0]["password"],
      "RSA-OAEP",
      {
        md: forge.md.sha256.create(),
        mgf1: forge.mgf1.create(),
      }
    );
    const finalEncryptedPassword = forge.util.encode64(encryptedPassword);
    if (validateInputs()) {
      localStorage.clear();

      axios
        .post(APIConfig.BASE_URL + "/user/auth", {
          email: userData[0]["email"],
          password: finalEncryptedPassword,
        })
        .then((response) => {
          if (response.status === 200) {
            if (
              response.data.message !== undefined &&
              response.data.access_token === undefined
            ) {
              ReactDOM.render(
                <SnackbarMessage msgtype="error" msg={response.data.message} />,
                document.getElementById("snackbar")
              );
            } else {
              setAuthTokens(AuthResponseToAuthTokens(response.data));
              trackEvent({
                action: "user Logged in Successfully",
                username: response.data.name,
                useremail: response.data.email,
                id: response.data.client_id,
                time_of_login: new Date(),
              });

              localStorage.setItem(
                "user",
                JSON.stringify({
                  name: response.data.name,
                  email: response.data.email,
                  client_id: response.data.client_id,
                })
              );
              localStorage.setItem("loggedInFlag", true);
              if (
                process.env.REACT_APP_ENV_NAME === "staging" ||
                process.env.REACT_APP_ENV_NAME === "production"
              ) {
                capturePosthogEvent(
                  window.location.origin,
                  window.location.pathname,
                  "submit"
                );
              }
              getRegisteredEmail();

              history.push("/dashboard");
            }
          }
        })
        .catch((err) => {
          ReactDOM.render(
            <SnackbarMessage msgtype="error" msg={err.response.data.message} />,
            document.getElementById("snackbar")
          );
        });
    }
  };

  return (
    <React.Fragment>
      <div className="login">
        <DecentroBanner label={label} />
        <div className="login-section">
          <div className="form-section">
            <img
              className="fixed-logo-new"
              src="https://decentro-icons.s3.ap-south-1.amazonaws.com/forget-password-dashboard/decentro-blue-bg-logo.svg"
              alt="logo"
            />
            <h1>Welcome back! Please login to your account.</h1>
            <div className="input-section">
              <form
                onSubmit={handleSubmit}
                autoComplete="off"
                className="login-form"
              >
                <div className="inputs">
                  <div className="input-field">
                    <label>Email</label>
                    <input
                      className="type1"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      required
                    />
                    <span className="error" id="email_error"></span>
                  </div>

                  <div className="input-field">
                    <label>Password</label>
                    <input
                      className="type1"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      required
                    />
                    <span className="error" id="password_error"></span>
                  </div>
                </div>
                <div className="forgot-your-password">
                  <Link to="/forgot-password">Forgot your password?</Link>
                </div>
                <button className="login-button" type="submit">
                  Login
                </button>
              </form>
              {/* <div className="signup-message">
                <p>
                  Don't have an account yet? <Link to="/signup">SIGN UP</Link>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <StickyFooter />
    </React.Fragment>
  );
};

export default Login;

export const TrackedLogin = track(
  { section: "Login" },
  { dispatchOnMount: (contextData) => ({ action: "pageLoaded" }) }
)(Login);
