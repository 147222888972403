import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import apiEndpointList from "../../../../../config/core_banking/endpoint";
import { APIConfig } from "../../../../../services/apiConfiguration";
import SnackbarMessage from "../../../../Shared/SnackbarMessage/SnackbarMessage";
import posthog from "posthog-js";

// styles import
import "./Toggle2FA.scss";

const Toggle2FA = () => {
  const [mfaStatus, setMfaStatus] = React.useState({
    enable2FA: "",
  });

  const kycToggle = (e) => {
    const { name, checked } = e.target;
    setMfaStatus({
      ...mfaStatus,
      [name]: checked,
    });

    mfaPreference(checked);
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (checked) {
        posthog.capture("Enable 2FA Toggle", {
          path: window.location.origin + window.location.pathname,
          customer_name: JSON.parse(localStorage.getItem("user")).client_id,
          user_email: JSON.parse(localStorage.getItem("user")).email,
        });
      } else {
        posthog.capture("Disable 2FA Toggle", {
          path: window.location.origin + window.location.pathname,
          customer_name: JSON.parse(localStorage.getItem("user")).client_id,
          user_email: JSON.parse(localStorage.getItem("user")).email,
        });
      }
    }
  };

  //  MFA toggle status api
  const mfaToggleStatus = () => {
    // setting to default state
    setMfaStatus({
      enable2FA: "",
    });

    APIConfig.API_Client.get(
      apiEndpointList.MFA_TOGGLE_DETAIL.baseUrl +
        apiEndpointList.MFA_TOGGLE_DETAIL.endpoint
    )
      .then((response) => {
        setMfaStatus({
          enable2FA: response?.data?.sms_mfa_enabled,
        });
      })
      .catch((error) => {});
  };

  const mfaPreference = (status) => {
    APIConfig.API_Client.put(
      apiEndpointList.MFA_PREFERENCE.baseUrl +
        apiEndpointList.MFA_PREFERENCE.endpoint,
      {
        sms_mfa_enabled: status,
      }
    )
      .then((response) => {
        if (status === true) {
          ReactDOM.render(
            <SnackbarMessage
              msgtype="success"
              msg={"Successfully enabled 2FA"}
            />,
            document.getElementById("snackbar")
          );
        }
        if (status === false) {
          ReactDOM.render(
            <SnackbarMessage
              msgtype="success"
              msg={"Successfully disabled 2FA"}
            />,
            document.getElementById("snackbar")
          );
        }
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="error" msg={"Failed to enable 2FA"} />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    mfaToggleStatus();
  }, []);

  return (
    <div class="manage-2fa-toggle-card">
      {/* toggle with label */}
      <div class="toggle-container">
        <p className="toggle-card-heading" onClick={mfaPreference}>
          Enable 2FA for login
        </p>

        {/* toggle component */}
        {mfaStatus.enable2FA === "" ? (
          <span className="loading-text">Loading...</span>
        ) : (
          <div className="toggle-wrapper">
            <div className="auth-toggle-container">
              <div className="toggle-section">
                <span className={!mfaStatus.enable2FA && "text-toggle"}>
                  No
                </span>
                <input
                  id="auth-switch"
                  className="checkbox"
                  name="enable2FA"
                  value={mfaStatus.enable2FA}
                  checked={mfaStatus.enable2FA}
                  onChange={kycToggle}
                  type="checkbox"
                />
                <label htmlFor="auth-switch" className="toggle"></label>
                <span className={mfaStatus.enable2FA && "text-toggle"}>
                  Yes
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* text detail */}
      <p className="enable-login-detail">
        Secure your account by enabling 2FA each time you login to your account.
        A verification OTP will sent to your registered mobile number each time
        you try to login.
      </p>
    </div>
  );
};

export default Toggle2FA;
