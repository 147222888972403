import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
// Components
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import { Loader } from "rsuite";
import ManageBeneficiarySuccess from "../../ManageBeneficiary/ManageBeneficiarySuccess/ManageBeneficiarySuccess";
import SnackbarMessage from "../../Shared/SnackbarMessage/SnackbarMessage";
// Formik Imoports
import { useFormik } from "formik";
import * as Yup from "yup";
// Axios Imports
import Axios from "axios";
// Regex Imports
import { REGEXP } from "../../utilities/validators/inputValidators";
// Utils
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/core_banking/endpoint";
// Styles
import "./ManageBeneficiaryForm.scss";

function ManageBeneficairyFormFormik({ data, tableEditRow }) {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  // Bank Providers
  let transferTypeArray = ["RTGS", "NEFT", "UPI", "IMPS"];
  const transferTypes = transferTypeArray.map((value) => ({
    value,
    label: value,
  }));

  // for holding Provider Bank
  const [providerApiData, setProviderApiData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // * Based on if the user is editting data, the inital Values for the form are being set here.
  let initialValues = tableEditRow
    ? {
      transfer_type: { value: data.transfer_type, label: data.transfer_type },
      beneficiary_name: data.beneficiary_name,
      sender_acc_no: data.sender_bank_account,
      beneficiary_email: data.beneficiary_email,
      sender_mob_no: "",
      beneficiary_mob_no: data.beneficiary_mobile,
      beneficiary_acc_no: data.beneficiary_account_number,
      beneficiary_ifsc_no: data.ifsc_code,
      beneficiary_upi: data.beneficiary_upi_id,
      bank_provider: { label: data.provider, value: data.provider },
    }
    : {
      transfer_type: "",
      bank_provider: "",
      beneficiary_name: "",
      sender_acc_no: "",
      beneficiary_email: "",
      sender_mob_no: "",
      beneficiary_mob_no: "",
      beneficiary_acc_no: "",
      beneficiary_ifsc_no: "",
      beneficiary_upi: "",
    };

  const {
    nameRegex,
    ifscRegex,
    emailRegex,
    panRegex,
    UPIIDRegex,
    phoneNumberRegex,
    accountNumberRegex,
  } = REGEXP;

  // Schema for validating form fields corresponding to regex imported.
  const validationSchema = Yup.object({
    transfer_type: Yup.mixed().required("Transfer Types is required"),
    bank_provider: Yup.mixed().required("Bank Provider is required"),
    beneficiary_name: Yup.string()
      .matches(nameRegex)
      .required("Benficiary Name is required"),
    sender_acc_no: Yup.string()
      .matches(accountNumberRegex)
      .required("Account is required"),
    beneficiary_email: Yup.string()
      .matches(emailRegex)
      .required("Email is required"),
    sender_mob_no: Yup.string().matches(phoneNumberRegex),
    beneficiary_mob_no: Yup.string()
      .matches(phoneNumberRegex)
      .required("Phone no. is required"),
    beneficiary_acc_no: Yup.string().when("transfer_type", {
      is: (transfer_type) => transfer_type?.value !== "UPI",
      then: () => Yup.string().matches(accountNumberRegex).required(),
      otherwise: () => Yup.string().notRequired(),
    }),
    beneficiary_ifsc_no: Yup.string().when("transfer_type", {
      is: (transfer_type) => transfer_type?.value !== "UPI",
      then: () => Yup.string().matches(ifscRegex).required(),
      otherwise: () => Yup.string().notRequired(),
    }),
    beneficiary_upi: Yup.string().when("transfer_type", {
      is: (transfer_type) => transfer_type?.value === "UPI",
      then: () => Yup.string().matches(UPIIDRegex).required(),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    // This function will run when user will submit the form after it is validated.
    // * - If tableEditRow is true: then two conditions are checked wheather it have account number or UPI ID and then payload is sent for "modify" action.
    // * - If tableEditRow is false: then the payload for "add" action is to be sent.
    onSubmit: (values, action) => {
      const payloadData = tableEditRow
        ? data.beneficiary_account_number != "-"
          ? {
            action: "modify",
            beneficiary_code: data.beneficiary_code,
            account_number: data.beneficiary_account_number,
            ifsc: data.ifsc_code,
            transfer_type: data.transfer_type,
            name: values.beneficiary_name,
            email: values.beneficiary_email,
            mobile: values.beneficiary_mob_no,
            sender_account_number: data.sender_bank_account,
          }
          : {
            action: "modify",
            beneficiary_code: data.beneficiary_code,
            upi_handle: data.beneficiary_upi_id,
            transfer_type: data.transfer_type,
            sender_account_number: data.sender_bank_account,
            name: values.beneficiary_name,
            email: values.beneficiary_email,
            mobile: values.beneficiary_mob_no,
          }
        : {
          action: "ADD",
          account_number: values.beneficiary_acc_no,
          email: values.beneficiary_email,
          ifsc: values.beneficiary_ifsc_no,
          mobile: values.beneficiary_mob_no,
          name: values.beneficiary_name,
          sender_account_number: values.sender_acc_no,
          sender_mobile: values.sender_mob_no,
          transfer_type: values.transfer_type.value,
          upi_handle: values.beneficiary_upi,
        };

      const bank_provider = values.bank_provider.value;

      // * based on if form is being editted or a new beneficiary is added different API calls are to be made.
      tableEditRow
        ? editBeneficiaryApi(payloadData, bank_provider, action)
        : addBeneficiaryApi(payloadData, bank_provider, action);
    },
  });

  // Fetching the Providers to show in dropdown.
  const cvaProvider = () => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.PROVIDER_ACCOUNTS.baseUrl +
      apiEndpointList.PROVIDER_ACCOUNTS.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          value: item.bank_code,
          label: item.bank_name,
          bank_id: item.bank_id,
        }));
        setIsLoading(false);
        setProviderApiData(options);
      })
      .catch((error) => {
        console.error("error from Manage Beneficiary Form", error);
      });
  };

  useEffect(() => cvaProvider(), []);

  // To add new Beneficiary
  const addBeneficiaryApi = (payload, provider_code, action) => {
    ReactDOM.render(
      <Loader size="xs" />,
      document.getElementById("user-config-loader")
    );
    APIConfig.API_Client.post(
      apiEndpointList.MANAGE_BENEFICIARY_API.baseUrl +
      apiEndpointList.MANAGE_BENEFICIARY_API.endpoint,

      payload,
      { headers: { provider_code: provider_code } },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        action.resetForm();
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <ManageBeneficiarySuccess
            headingMsg={response?.data?.message}
            data={response?.data?.data}
            type={payload?.action}
          />,
          document.getElementById("manage-beneficiary-components")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        // finally setting form submission false.
        action.setSubmitting(false);
      });
  };

  // To edit existing Beneficiary
  const editBeneficiaryApi = (payload, code, action) => {
    ReactDOM.render(
      <Loader size="xs" />,
      document.getElementById("user-config-loader")
    );

    APIConfig.API_Client.post(
      apiEndpointList.MANAGE_BENEFICIARY_API.baseUrl +
      apiEndpointList.MANAGE_BENEFICIARY_API.endpoint,
      payload,
      { headers: { provider_code: code } },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        action.resetForm();
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <ManageBeneficiarySuccess
            data={response.data.data}
            type={payload.action}
          />,
          document.getElementById("manage-beneficiary-components")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        // finally setting form submission false.
        action.setSubmitting(false);
      });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="bank_provider"
                name="bank_provider"
                onChange={(selectedOption) => {
                  formik.setFieldValue("bank_provider", selectedOption);
                }}
                onBlur={() => formik.setFieldTouched("bank_provider", true)}
                value={formik.values.bank_provider}
                options={providerApiData}
                noOptionsMessage={() => "No Provider Exists"}
                label="Select Provider"
                required={true}
                isLoading={isLoading}
                placeholder="Bank Provider"
                isformatOptionLabel={true}
                isDisabled={tableEditRow}
                isToolTip={"Select the Bank Provider to add beneficiary"}
              />
              <TextFieldInput
                id="beneficiary_name"
                name="beneficiary_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_name}
                touched={formik.touched.beneficiary_name}
                error={formik.errors.beneficiary_name}
                placeholder="Enter Beneficiary Name"
                label="Beneficiary Name"
                required={true}
                disabled={false}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="transfer_type"
                name="transfer_type"
                onChange={(selectedOption) => {
                  formik.setFieldValue("transfer_type", selectedOption);
                }}
                onBlur={() => formik.setFieldTouched("transfer_type", true)}
                value={formik.values.transfer_type}
                options={transferTypes}
                noOptionsMessage={() => "No Transfer Type Exists"}
                label="Transfer Type"
                required={true}
                placeholder="Transfer Type"
                isformatOptionLabel={true}
                isDisabled={tableEditRow}
              />
              <TextFieldInput
                id="beneficiary_mob_no"
                name="beneficiary_mob_no"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_mob_no}
                touched={formik.touched.beneficiary_mob_no}
                error={formik.errors.beneficiary_mob_no}
                placeholder="Enter Beneficiary Mobile No"
                label="Beneficiary Mobile No"
                required={true}
                disabled={false}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="sender_acc_no"
                name="sender_acc_no"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sender_acc_no}
                touched={formik.touched.sender_acc_no}
                error={formik.errors.sender_acc_no}
                placeholder="Enter Sender's Account Number"
                label="Sender's Account Number"
                required={true}
                disabled={tableEditRow}
                isToolTip={
                  "Virtual account number against which the beneficiary has to be added"
                }
              />
              <TextFieldInput
                id="beneficiary_email"
                name="beneficiary_email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_email}
                touched={formik.touched.beneficiary_email}
                error={formik.errors.beneficiary_email}
                placeholder="Enter Beneficiary Email"
                label="Beneficiary Email"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              {" "}
              {tableEditRow ? (
                <TextFieldInput
                  id="beneficiary_code"
                  name="beneficiary_code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={data?.beneficiary_code}
                  // touched={formik.touched.sender_mob_no}
                  // error={formik.errors.sender_mob_no}
                  // placeholder="Enter Sender's Mobile No"
                  label="Beneficiary Code"
                  required={true}
                  disabled={tableEditRow}
                />
              ) : (
                <TextFieldInput
                  id="sender_mob_no"
                  name="sender_mob_no"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sender_mob_no}
                  touched={formik.touched.sender_mob_no}
                  error={formik.errors.sender_mob_no}
                  placeholder="Enter Sender's Mobile No"
                  label="Sender's Mobile No"
                  required={false}
                  disabled={false}
                />
              )}
              {formik?.values?.transfer_type &&
                formik?.values?.transfer_type?.value === "UPI" ? (
                <TextFieldInput
                  id="beneficiary_upi"
                  name="beneficiary_upi"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.beneficiary_upi}
                  touched={formik.touched.beneficiary_upi}
                  error={formik.errors.beneficiary_upi}
                  placeholder="Enter UPI"
                  label="Enter UPI ID"
                  required={true}
                  disabled={tableEditRow}
                />
              ) : (
                <TextFieldInput
                  id="beneficiary_acc_no"
                  name="beneficiary_acc_no"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.beneficiary_acc_no}
                  touched={formik.touched.beneficiary_acc_no}
                  error={formik.errors.beneficiary_acc_no}
                  placeholder="Enter Beneficiary Account No"
                  label="Beneficiary Account Number"
                  required={true}
                  disabled={tableEditRow}
                />
              )}
            </div>

            <div className="ui-form-inputs-section">
              {tableEditRow && formik?.values?.beneficiary_upi ? (
                <TextFieldInput
                  id="upi_id"
                  name="upi_id"
                  value={formik?.values?.beneficiary_upi}
                  touched={formik?.touched?.beneficiary_upi}
                  error={formik?.errors?.beneficiary_upi}
                  label="Beneficiary UPI"
                  required={false}
                  disabled={tableEditRow}
                />
              ) : null}
            </div>
            {formik?.values?.transfer_type &&
              formik?.values?.transfer_type?.value !== "UPI" && (
                <div
                  className="ui-form-inputs-section"
                  style={{
                    width: "100%",
                  }}
                >
                  <TextFieldInput
                    id="beneficiary_ifsc_no"
                    name="beneficiary_ifsc_no"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.beneficiary_ifsc_no}
                    touched={formik.touched.beneficiary_ifsc_no}
                    error={formik.errors.beneficiary_ifsc_no}
                    placeholder="Enter IFSC code"
                    label="Beneficiary IFSC"
                    required={true}
                    disabled={tableEditRow}
                  />
                </div>
              )}

            {/* <div className="ui-form-inputs-section">
              <TextFieldInput
                id="beneficiary_mob_no"
                name="beneficiary_mob_no"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_mob_no}
                touched={formik.touched.beneficiary_mob_no}
                error={formik.errors.beneficiary_mob_no}
                placeholder="Enter Beneficiary Mobile No"
                label="Beneficiary Mobile No"
                required={true}
                disabled={false}
              />
            </div> */}

            {/* <div className="ui-form-inputs-section">
              {formik?.values?.transfer_type &&
              formik?.values?.transfer_type?.value === "UPI" ? (
                <TextFieldInput
                  id="beneficiary_upi"
                  name="beneficiary_upi"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.beneficiary_upi}
                  touched={formik.touched.beneficiary_upi}
                  error={formik.errors.beneficiary_upi}
                  placeholder="Enter UPI"
                  label="Enter UPI ID"
                  required={true}
                  disabled={tableEditRow}
                />
              ) : (
                <TextFieldInput
                  id="beneficiary_acc_no"
                  name="beneficiary_acc_no"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.beneficiary_acc_no}
                  touched={formik.touched.beneficiary_acc_no}
                  error={formik.errors.beneficiary_acc_no}
                  placeholder="Enter Beneficiary Account No"
                  label="Beneficiary Account Number"
                  required={true}
                  disabled={tableEditRow}
                />
              )}
            </div> */}

            <div className="ui-button-container">
              <button
                type="submit"
                className={`submit-btn ${formik.isValid && formik.dirty && !formik.isSubmitting
                  ? "active"
                  : ""
                  }`}
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting
                  ? "Loading..."
                  : tableEditRow
                    ? "Modify Beneficiary"
                    : "Add Beneficiary"}
                <span
                  id="user-config-loader"
                  style={{ display: "flex" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default ManageBeneficairyFormFormik;
