// React Imports
import React, { createContext, useState } from 'react';
import ReactDOM from "react-dom";
// Utils
import { APIConfig } from '../services/apiConfiguration';
import apiEndpointList from '../config/core_banking/endpoint';
import SnackbarMessage from '../components/Shared/SnackbarMessage/SnackbarMessage';
import Axios from 'axios';


const CallbackActionContext = createContext();

export const CallbackActionProvider = ({ children }) => {
  // Switching card
  const [cardType, setCardType] = useState('allCallback');
  // if table Edit
  const [tableEditData, setTableEditData] = useState({ item: "", edit: false });
  const [editCallbackItem, setEditCallbackItem] = useState({});

  const [showModal, setShowModal] = useState(false)
  // states related with data to be shown.
  const [listIds, setListIds] = useState("");
  const [showCallbackList, setShowCallbackList] = useState(true);
  const [resetCallback, setResetCallback] = useState(false);

  // Input Fields
  const [callbackTypeName, setCallbackTypeName] = useState(null);
  const [httpTypeName, setHttpTypeName] = useState(null);
  const [headerField, setHeaderField] = useState("");
  const [urlField, setUrlField] = useState("");

  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  // shows "new callback" screen
  const addNewCallback = () => {
    setCardType('newCallback');
  };

  // shows "all callback" screen
  const allCallback = () => {
    setCardType('allCallback');
    setTableEditData({
      item: "",
      edit: false
    });
    setCallbackTypeName(null);
    setHttpTypeName(null);
    setHeaderField("");
    setUrlField("");
  }

  const openModal = () => setShowModal(true)
  const callbackEditItem = (item) => {
    setEditCallbackItem(item)
  }

  const addCallbackApi = (ifEdit, payload) => {
    setShowCallbackList(false);
    // if update then "put" else "post" request
    let requestType = ifEdit ? "put" : "post"

    APIConfig.API_Client[requestType](
      APIConfig.BASE_URL + apiEndpointList.ALL_CALLBACKS.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        allCallback()
        setResetCallback(!resetCallback);
        setShowCallbackList(true);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  return (
    <CallbackActionContext.Provider
      value={
        {
          cardType,
          tableEditData,
          setTableEditData,
          addNewCallback,
          allCallback,
          showModal,
          setShowModal,
          openModal,
          editCallbackItem,
          callbackEditItem,
          showCallbackList,
          listIds,
          addCallbackApi,
          callbackTypeName,
          setCallbackTypeName,
          httpTypeName,
          setHttpTypeName,
          headerField,
          setHeaderField,
          urlField,
          setUrlField
        }
      }
    >
      {children}
    </CallbackActionContext.Provider>
  )
}

export default CallbackActionContext;