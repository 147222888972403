import React, { useContext, useEffect } from "react";
import TimeCell from "../../utilities/TimeCell";
import ReactDataTable from "../Common/ReactDataTable";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import ManageBeneficiaryModal from "../ManageBeneficiaryModal/ManageBeneficiary/ManageBeneficiary";
import { ManageBeneficairyActionProvider } from "../../contexts/ManageBeneficiaryContext";
import { BeneficiaryContextProvider } from "./ManageBeneficiaryContext";
import EditCell from "./EditCell";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import posthog from "posthog-js";
import { capturePosthogEvent } from "../../utilities/posthogUtils";

const transactionColumnList = [
  {
    accessor: "beneficiary_name",
    name: "Beneficiary Name",
    showInitial: true,
  },
  {
    accessor: "beneficiary_account_number",
    name: "Beneficiary Account",
    showInitial: true,
  },
  {
    accessor: "beneficiary_code",
    name: "Beneficiary Code",
    showInitial: true,
  },
  {
    accessor: "beneficiary_upi_id",
    name: "Beneficiary UPI",
    showInitial: true,
  },
  {
    accessor: "created_on",
    name: "Date-Time",
    cell: TimeCell,
    showInitial: true,
  },
  {
    accessor: "status",
    title: "status",
    name: "status",
    showInitial: true,
    hasPopup: true,
    cell: StatusCell,
  },
  {
    accessor: "transfer_type",
    name: "Transfer Type",
    showInitial: true,
    hasPopup: true,
  },

  { accessor: "ifsc_code", name: "IFSC Code" },
  {
    accessor: "beneficiary_email",
    name: "Beneficiary Email",
  },
  {
    accessor: "beneficiary_mobile",
    name: "Beneficiary Mobile",
  },
  {
    accessor: "sender_bank_account",
    name: "Remitter Account",
  },
  {
    accessor: "",
    cell: EditCell,
    name: "Manage",
    showInitial: true,
    // clickFn: viewTransactions,
  },
];
const Beneficiary = ({ openUpiLink }) => {
  const { isLoading } = useContext(BeneficiaryContextProvider);
  return (
    <>
      <button
        className={isLoading ? "btn-loading" : "virtual-accounts-btn"}
        style={{ backgroundColor: "#0092ff" }}
        onClick={() => {
          if (!isLoading) {
            if (
              process.env.REACT_APP_ENV_NAME === "staging" ||
              process.env.REACT_APP_ENV_NAME === "production"
            ) {
              capturePosthogEvent(
                window.location.origin,
                window.location.pathname,
                "cta"
              );
            }
            openUpiLink();
          }
        }}
      >
        Add Beneficiary
      </button>
    </>
  );
};

const ManageBeneficiaryReact = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("Manage Beneficiaries View", {
        path: "https://staging.dashboard.decentro.tech/dashboard/payments/manage-beneficiaries",
        customer_name: JSON.parse(localStorage.getItem("user")).client_id,
        user_email: JSON.parse(localStorage.getItem("user")).email,
      });
    }
  }, []);
  const {
    initialLoading,
    isError,
    errorResponse,
    refreshTable,
    setShowModalDialog,
    showModify,
    setShowModify,
    selectedAccount,
    showModalDialog,
    tableData,
    totalCount,
    fetchData,
    filterList,
    firstApiCall,
  } = useContext(BeneficiaryContextProvider);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  function openUpiLink() {
    setShowModalDialog(true);
  }
  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"Manage Beneficiaries"}
          isModalPresent={true}
          showDownloadBtn={false}
          modalComponent={<Beneficiary openUpiLink={openUpiLink} />}
          columnList={transactionColumnList}
          provider={BeneficiaryContextProvider}
          ModalDialog={
            <ManageBeneficairyActionProvider>
              {showModify ? (
                <ManageBeneficiaryModal
                  tableEditRow={true}
                  data={selectedAccount}
                  goBackToTable={refreshTable}
                />
              ) : (
                <ManageBeneficiaryModal
                  tableEditRow={false}
                  goBackToTable={refreshTable}
                />
              )}
            </ManageBeneficairyActionProvider>
          }
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default ManageBeneficiaryReact;
