import React, { useEffect } from "react";
import "./Reports.scss";
import ReportsForm from "../../components/ReportsFrom/ReportsForm.js";
import posthog from "posthog-js";

function Reports() {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("Reports View", {
        path: window.location.origin + window.location.pathname,
        customer_name: JSON.parse(localStorage.getItem("user")).client_id,
        user_email: JSON.parse(localStorage.getItem("user")).email,
      });
    }
  }, []);
  return (
    <React.Fragment>
      <div className="ReportsMainDialog">
        <ReportsForm />
      </div>
    </React.Fragment>
  );
}

export default Reports;
