import React, { useState } from 'react'
// Components
import DecentroBanner from '../../components/Shared/DecentroBanner/DecentroBanner.js';
import StickyFooter from '../../components/StickyFooter/StickyFooter.js';
// formik
import { useFormik } from 'formik';
import * as Yup from "yup";
// utils
import { Link, useHistory } from "react-router-dom";
import { REGEXP } from '../../components/utilities/validators/inputValidators.js';
import { APIConfig } from '../../services/apiConfiguration.js';
import apiEndpointList from '../../config/core_banking/endpoint.js';
// Axios
import Axios from 'axios';
// styles
import './ForgotPassword.scss';


let label = { 'light': "We’re here", 'bold': "for you!" };

const ForgotPassword = () => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    const [visibleScreen, setVisibleScreen] = useState("forgotPassword")
    const [userEmail, setUserEmail] = useState("");
    const validEmail = "Please enter valid email"
    const [validEmailMsg, setValidEmailMsg] = useState(validEmail)

    const history = useHistory();

    // const validEmailMsg = "Please enter valid email"
    const initialValues = {
        user_email: ""
    };
    const { emailRegex } = REGEXP;
    // Schema for validating form fields corresponding to regex imported.
    const validationSchema = Yup.object({
        user_email: Yup.string()
            .matches(emailRegex)
            .required(validEmailMsg),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,

        // This function will run when user will submit the form after it is validated.
        onSubmit: (values, action) => {
            const payloadData = {
                email: values.user_email
            };
            setUserEmail(values.user_email)
            resetPassword(payloadData, action)
        },
    });

    const resetPassword = (payload, action) => {
        action.setSubmitting(true);

        APIConfig.API_Client.post(
            apiEndpointList.FORGOT_PASSWORD.baseUrl +
            apiEndpointList.FORGOT_PASSWORD.endpoint,
            payload,
            { cancelToken: source.token }
        )
            .then(res => {
                if (res.status === 200) {
                    setVisibleScreen("success")
                }
            })
            .catch(e => {
                setValidEmailMsg(e.response.data.response_message)
                formik.setFieldError("user_email", validEmailMsg)
            })
            .finally(() => {
                // finally setting form submission false.
                action.setSubmitting(false);
            });

    }
    // Keeping for future reference
    const ForgotPasswordForm = () => {
        return (
            <form autoComplete="off" className="password-form" onSubmit={formik.handleSubmit}>
                <div className="inputs">
                    <label>Email</label>
                    <input
                        id="user_email"
                        className={`type1 ui-form-input-box ${formik.touched.user_email && formik.errors.user_email ? "input-error" : ""
                            }`}
                        type="email"
                        name="user_email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.user_email}
                        required
                    />
                    {formik.touched.user_email && formik?.errors?.user_email && <span className="error" id="email_error">{validEmailMsg}</span>}
                </div>
                <button className="login-button" type="submit" disabled={!formik.isValid || !formik.dirty}
                >
                    Send Reset Link
                </button>
                <div className='back-to-login'>
                    <Link to="/login">
                        Back to login
                    </Link>

                </div>
            </form>
        )
    }

    const Success = () => {
        return (
            <>
                <Link to="/login">
                    <button className="back-login" type="submit"
                    >
                        Back to login
                    </button>
                </Link>

            </>
        )
    }

    let heading, message, renderComponent;
    if (visibleScreen === "forgotPassword") {
        heading = "Reset Your Password";
        // due to formik form handling rendering form component inside another component was not working as expected.
        // renderComponent = <ForgotPasswordForm />;
        message = "Enter the registered email address  with Decentro, and we\"ll send a link to reset your password."
    } else if (visibleScreen === "success") {
        heading = "Check your email";
        // renderComponent = <Success />;
        message = `Thanks! We have sent a password  reset email and the next steps on ${userEmail}. Didn't receive an email? Please check your email and look out in the spam folder.`
    }

    return (
        <React.Fragment>
            <div className="forgot-password">
                <DecentroBanner label={label} />
                <div className="password-section">
                    <div className="form-section">
                        <img className="fixed-logo-new" src="https://decentro-icons.s3.ap-south-1.amazonaws.com/forget-password-dashboard/decentro-blue-bg-logo.svg" alt="logo" />
                        <h1>{heading}</h1>
                        <div className='reset-message'>{message}</div>

                        <div className="input-section">
                            {visibleScreen === "forgotPassword"
                                ?
                                <form autoComplete="off" className="password-form" onSubmit={formik.handleSubmit}>
                                    <div className="inputs">
                                        <label>Email</label>
                                        <input
                                            id="user_email"
                                            className={`type1 ui-form-input-box ${formik.touched.user_email && formik.errors.user_email ? "input-error" : ""
                                                }`}
                                            type="email"
                                            name="user_email"
                                            onChange={(e) => {
                                                setValidEmailMsg(validEmail)
                                                formik.handleChange(e)
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.user_email}
                                            required
                                        />
                                        {formik.touched.user_email && formik?.errors?.user_email && <span className="error" id="email_error">{validEmailMsg}</span>}
                                    </div>
                                    <button className="login-button" type="submit"
                                        disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                                    >
                                        Send Reset Link
                                    </button>
                                    <div className='back-to-login'>
                                        <Link to="/login">
                                            Back to login
                                        </Link>

                                    </div>
                                </form>
                                :
                                <Success />}
                            {/* {renderComponent} */}
                        </div>
                    </div>
                </div>
            </div>
            <StickyFooter />
        </React.Fragment>
    )
}

export default ForgotPassword;