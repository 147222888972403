import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Components
import StatusCell from "../../utilities/StatusCell/StatusCell";
import TimeCell from "../../utilities/TimeCell";
import ReactDataTable from "../Common/ReactDataTable";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import BulkCapabilitiesLink from "./BulkCapabilitiesLink/BulkCapabilitiesLink";
import SnackbarMessage from "../Shared/SnackbarMessage/SnackbarMessage";
import DownloadLinkCell from "../../utilities/DownloadLinkCell/DownloadLinkCell";
// Utils
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/core_banking/endpoint";
import posthog from "posthog-js";
import ShowBlueValue from "../VirtualAccountDataTable/ShowBlueValue";
import { capturePosthogEvent } from "../../utilities/posthogUtils";
// Contexts
import { BulkCapabilitiesContextProvider } from "./Contexts/BulkCapabilitiesContext";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const BulkCapabilities = () => {
  const {
    isError,
    errorResponse,
    tableData,
    totalCount,
    filterList,
    fetchData,
    firstApiCall,
    searchParameter,
    setSearchParameter,
    currentView,
    setCurrentView,
    childPayload,
    setChildPayload,
    childTableData,
    setChildTableData,
    setChildFilterList,
    childFilterList,
    fetchChildData,
    childTotalCount,
    userURN
  } = useContext(BulkCapabilitiesContextProvider);

  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("All Transactions View", {
        path: window.location.origin + window.location.pathname,
        customer_name: JSON.parse(localStorage.getItem("user")).client_id,
        user_email: JSON.parse(localStorage.getItem("user")).email,
      });
    }
  }, []);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);


  useEffect(() => {
    if (currentView === "child") {
      if (childTableData.length >= childTotalCount && childTableData.length !== 0) return;
      const prefetchLimit = 200;
      const endIndex = childFilterList.currentPage * childFilterList.resultsPerPage;
      const limitConstant =
        Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
        2 * childFilterList?.resultsPerPage;
      if (childTotalCount > 0) {
        if (childTableData.length < limitConstant && firstApiCall === false) {
          fetchChildData(childTableData.length + 1, undefined, undefined, undefined, false, userURN);
        }
      } else if (
        childTotalCount === 0 &&
        childTableData.length === 0 &&
        firstApiCall === true
      ) {
        fetchChildData(childTableData.length + 1, undefined, undefined, undefined, false, userURN);
      }
    }
  }, [childFilterList.currentPage]);

  // based on currentView the Data Table is being displayed
  const USER_MANAGEMENT_CARD =
    currentView === "parent" ? <Parent /> : <Child />;
  return <>{USER_MANAGEMENT_CARD}</>;
};

// Initial Parent component that is being rendered.
const Parent = () => {
  const {
    isError,
    errorResponse,
    currentView,
    setCurrentView,
    setChildPayload,
    childPayload,
    tableData,
    fetchData,
    setfirstApiCall,
    fetchChildData,
    setFilterList,
    setSearchParameter,
    setCurrentSearchScope,
    showModalDialog,
    setShowModalDialog,
    refreshTable,
    userURN,
    setUserURN,
    setTableData,
    setIsLoading,
    setChildFilterList,
  } = useContext(BulkCapabilitiesContextProvider);

  const [bulkPayoutCTA, setBulkPayoutCTA] = useState(false);
  // checking 2FA
  const [cta2faEnabled, setCta2faEnabled] = useState(false);

  const [isProdEnv, setIsProdEnv] = useState(false);


  const changeView = (prop) => {
    setCurrentView("child");
    setFilterList({
      search: "",
      resultsPerPage: 10,
      currentPage: 1,
    });
    setChildFilterList({
      search: "",
      resultsPerPage: 10,
      currentPage: 1,
    });
    setCurrentSearchScope("");
    setSearchParameter({
      bank_ref_number: false,
      decentro_urn: false,
      customer_ref_number: false,
    });
    setUserURN(prop);
    setChildPayload((prev) => ({
      ...prev,
      bulk_processing_urn: prop,
    }));
    setTableData([]);
    setIsLoading(true);
    fetchChildData(
      tableData.length + 1,
      undefined,
      undefined,
      undefined,
      false,
      prop
    );
  };

  const transactionColumnList = [
    {
      name: "Decentro URN",
      accessor: "decentro_company_urn",
      clickFn: changeView,
      cell: ShowBlueValue,
      showInitial: true,
    },
    {
      name: "Bulk Processing Type",
      accessor: "bulk_processing_type",
      showInitial: true,
      hasPopup: true,
    },
    {
      name: "Created On",
      accessor: "date_time",
      showInitial: true,
      cell: TimeCell,
    },
    {
      name: "Status",
      accessor: "status",
      showInitial: true,
      hasPopup: true,
      cell: StatusCell,
    },
    {
      name: "Total Records",
      accessor: "total_records",
      showInitial: true,
      cell: (row) => (row?.getValue() === null ? "-" : row?.getValue()),
    },
    {
      name: "Processed Records",
      accessor: "processed_records",
      showInitial: true,
      cell: (row) => (row?.getValue() === null ? "-" : row?.getValue()),
    },
    {
      name: "Success Records",
      accessor: "success_records",
      showInitial: true,
      cell: (row) => (row?.getValue() === null ? "-" : row?.getValue()),
    },
    {
      name: "Failure Records",
      accessor: "failure_records",
      showInitial: true,
      cell: (row) => (row?.getValue() === null ? "-" : row?.getValue()),
    },
    {
      accessor: "input_file",
      name: "Input File",
      showInitial: true,
      cell: (row) => DownloadLinkCell(row?.getValue())
    },
    {
      accessor: "output_file",
      name: "Output File",
      showInitial: true,
      cell: (row) => DownloadLinkCell(row?.getValue())
    },
  ];

  const BulkCTA = ({ openLink, cta2faEnabled }) => {
    const { isLoading } = useContext(BulkCapabilitiesContextProvider);
    return (
      <>
        {bulkPayoutCTA ? (<div className="payout-action-container">
          {!cta2faEnabled ? (
            <p className="action-alert-text">Activate 2FA to initiate payout</p>
          ) : (
            ""
          )}
          <button
            className={
              isLoading
                ? "btn-loading"
                : `payouts-action-btn ${!cta2faEnabled ? "disabled" : ""}`
            }
            style={{ backgroundColor: "#0092ff" }}
            disabled={!cta2faEnabled}
            onClick={() => {
              if (!isLoading) {
                if (
                  process.env.REACT_APP_ENV_NAME === "staging" ||
                  process.env.REACT_APP_ENV_NAME === "production"
                ) {
                  capturePosthogEvent(
                    window.location.origin,
                    window.location.pathname,
                    "cta"
                  );
                }
                openLink();
              }
            }}
          >
            {!cta2faEnabled ? (
              <img
                style={{
                  width: "16px",
                  fontSize: "8px",
                }}
                className="payouts-action-icon"
                src="/images/cta-info.svg"
                alt="info icon"
              />
            ) : (
              ""
            )}
            Bulk Upload
          </button>
        </div>)
          :
          ""
        }


        {/* <button
          className={isLoading ? "btn-loading" : "virtual-accounts-btn"}
          style={{ backgroundColor: "#0092ff" }}
          onClick={() => {
            if (!isLoading) {
              if (
                process.env.REACT_APP_ENV_NAME === "staging" ||
                process.env.REACT_APP_ENV_NAME === "production"
              ) {
                capturePosthogEvent(
                  window.location.origin,
                  window.location.pathname,
                  "cta"
                );
              }
              openLink();
            }
          }}
        >
          Bulk Upload
        </button> */}
      </>
    );
  };

  function openLink() {
    setShowModalDialog(true);
  }

  //*  MFA toggle status api
  const mfaToggleStatus = () => {
    APIConfig.API_Client.get(
      apiEndpointList.MFA_TOGGLE_DETAIL.baseUrl +
      apiEndpointList.MFA_TOGGLE_DETAIL.endpoint
    )
      .then((response) => {
        setCta2faEnabled(response?.data?.sms_mfa_enabled);
      })
      .catch((error) => { });
  };

  //& Allow payouts API call
  const allowBulkCapabilitiesApi = () => {
    APIConfig.API_Client.post(
      apiEndpointList.ALLOW_BULK_CAPABILITIES.baseUrl +
      apiEndpointList.ALLOW_BULK_CAPABILITIES.endpoint,
      { client_id: JSON.parse(localStorage.getItem("user")).client_id },
      { cancelToken: source.token }
    )
      .then((response) => {
        setBulkPayoutCTA(response.data[0].enabled);
        if (response.data[0].enabled) {
          mfaToggleStatus(response.data[0].enabled)
        }

      })
      .catch((error) => {
        console.error("error from Bulk Capabilities", error)
      });
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV_NAME === "production") {
      allowBulkCapabilitiesApi();
      setIsProdEnv(true)
    }

    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "qa"
    ) {
      setBulkPayoutCTA(true);
      setCta2faEnabled(true);
    }
  }, []);

  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"Bulk Capabilities"}
          provider={BulkCapabilitiesContextProvider}
          modalComponent={<BulkCTA
            openLink={openLink}
            cta2faEnabled={cta2faEnabled}
          />}
          ModalDialog={<BulkCapabilitiesLink goBackToTable={refreshTable} />}
          isModalPresent={true}
          columnList={transactionColumnList}
          showDownloadBtn={false}
          showSearchParameter={{
            bank_ref_number: false,
            decentro_urn: true,
            customer_ref_number: false,
          }}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

const Child = () => {
  const {
    isError,
    errorResponse,
    currentView,
    setCurrentView,
    setChildPayload,
    childPayload,
    userURN,
    refreshTable,
    fetchData,
    setFilterList,
    setCurrentSearchScope,
    setSearchParameter,
    tableData,
    setTableData,
    setChildTableData,
    setChildTotalCount,
    setIsLoading,
    setChildFilterList,
    childFilterList
  } = useContext(BulkCapabilitiesContextProvider);

  const getTxnStatus = (row, code) => {
    APIConfig.API_Client.defaults.headers.get["provider_code"] = code;

    const cell = document.getElementById(
      `status-cell-${row.original.decentro_urn}`
    );
    if (!cell) return;

    APIConfig.API_Client.get(
      apiEndpointList.PAYOUTS_STATUS.baseUrl +
      apiEndpointList.PAYOUTS_STATUS.endpoint +
      `?decentro_txn_id=${row.original.decentro_urn}`,

      { cancelToken: source.token }
    )
      .then((response) => {
        if (response.data.status === "success") {
          const successElement = <div className="success">SUCCESS</div>;
          cell.innerHTML = "";
          ReactDOM.render(successElement, cell);
          ReactDOM.render(
            <SnackbarMessage
              msgtype="success"
              msg={"Status updated to Success"}
            />,
            document.getElementById("snackbar")
          );
          return;
        } else if (response.data.status === "failure") {
          const failureElement = <div className="failure">FAILURE</div>;
          cell.innerHTML = "";
          ReactDOM.render(failureElement, cell);
          ReactDOM.render(
            <SnackbarMessage
              msgtype="error"
              msg={"Status updated to Failure"}
            />,
            document.getElementById("snackbar")
          );
          return;
        }
        ReactDOM.render(
          <SnackbarMessage msgtype="warning" msg={"Status not updated"} />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        console.log(error);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={`${error?.response?.data?.message} | Status: ${error?.response?.data?.status}`}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  const childTransactionColumnList = [
    {
      accessor: "decentro_urn",
      name: "Decentro URN",
      showInitial: true,
    },
    {
      accessor: "created_on",
      name: "Created On",
      showInitial: true,
      cell: TimeCell,
    },
    {
      accessor: "api_status",
      name: "API Status",
      cell: StatusCell,
      showInitial: true,
      hasPopup: true,
    },
    {
      accessor: "transaction_status",
      name: "Transaction Status",
      showInitial: true,
      hasPopup: true,
      cell: (row) => (
        <div
          className="status-update"
          id={`status-cell-${row.row.original.decentro_urn}`}
        >
          <StatusCell {...row} />
          {(row.getValue() === "PENDING" ||
            row.getValue() === "pending" ||
            row.getValue() === "Pending") && (
              <div
                className="refresh-icon-wrapper"
                onClick={() =>
                  getTxnStatus(row.row, row.row.original.provider_code)
                }
              >
                <img
                  src="/images/refresh-icon.svg"
                  alt="Get Txn Status"
                  title="Get Txn Status"
                />
              </div>
            )}
        </div>
      ),
    },
    {
      accessor: "bank_reference_number",
      name: "Bank Ref",
      showInitial: true,
    },
    { accessor: "amount", name: "amount", showInitial: true },
    {
      accessor: "provider_code",
      name: "Provider Code",
      showInitial: false,
      hasPopup: false,
    },
    {
      accessor: "status_message",
      name: "Status Message",
      showInitial: true,
      hasPopup: true,
    },
    {
      accessor: "response_key",
      name: "Response Key",
      showInitial: false,
      hasPopup: true,
    },
    {
      accessor: "payer_account_number",
      name: "Payer Account Number",
      showInitial: false,
      hasPopup: false,
    },
    {
      accessor: "payee_account_number",
      name: "Payee Account Number",
      showInitial: false,
      hasPopup: false,
    },
    {
      accessor: "payee_ifsc",
      name: "Payee IFSC",
      showInitial: false,
      hasPopup: false,
    },
    {
      accessor: "payee_vpa",
      name: "Payee VPA",
      showInitial: false,
      hasPopup: false,
    },
  ];

  // Adding styles here for title to not mutate common react table component.
  const Title = `
    <div>
    Transaction Summary for <span class='userURN' style="color:#0092ff !important; font-size: 17px !important; font-weight: 600 !important">${userURN}</span>
    </div>
    `;

  const takeBack = () => {
    setCurrentSearchScope("");
    setSearchParameter({
      bank_ref_number: false,
      decentro_urn: false,
      customer_ref_number: false,
    });
    setCurrentView("parent");
    const resetFilters = {
      search: "",
      resultsPerPage: 10,
      currentPage: 1,
    };
    setFilterList(resetFilters);
    setChildFilterList(resetFilters)
    setChildTableData([]);
    setChildTotalCount(0);
    fetchData(1, resetFilters);
    setIsLoading(true);
  };

  return (
    <>
      {!isError ? (
        <ReactDataTable
          backFunction={takeBack}
          tableTitle={Title}
          provider={BulkCapabilitiesContextProvider}
          isModalPresent={false}
          columnList={childTransactionColumnList}
          showDownloadBtn={false}
          showDateFilter={false}
          showSearchParameter={{
            bank_ref_number: false,
            decentro_urn: true,
            customer_ref_number: false,
          }}
          dualTable={true}
          child={true}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default BulkCapabilities;
