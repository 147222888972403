import React, { useEffect } from "react";
import posthog from "posthog-js";
import Enable2FA from "./Enable2FA/Enable2FA";
// styles import
import "./ManageAccount.scss";

const ManageAccount = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("Manage 2FA View", {
        path: window.location.origin + window.location.pathname,
        customer_name: JSON.parse(localStorage.getItem("user")).client_id,
        user_email: JSON.parse(localStorage.getItem("user")).email,
      });
    }
  }, []);
  return (
    <div className="manage-2fa-card">
      {/* Toggle Card */}
      <Enable2FA />
    </div>
  );
};

export default ManageAccount;
