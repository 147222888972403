import React, { useContext, useEffect } from "react";
import "./PaymentsTransactionsDataTable.scss";
import TimeCell from "../../utilities/TimeCell";
import { PaymentsTransactionContextProvider } from "./PaymentsTransactionContext";
import ShowTransactionCell from "./ShowTransactionCell/ShowTransactionCell";
import ReactDataTable from "../Common/ReactDataTable";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import posthog from "posthog-js";

const transactionColumnList = [
  {
    accessor: "decentro_transaction_id",
    name: "Decentro URN",
    showInitial: true,
    cell: ShowTransactionCell,
  },
  {
    accessor: "bank_reference_number",
    name: "Bank Ref",
    showInitial: true,
  },
  {
    accessor: "timestamp",
    name: "Date-Time",
    cell: TimeCell,
    showInitial: true,
  },
  {
    accessor: "status",
    name: "status",
    hasPopup: true,
    showInitial: true,
    cell: StatusCell,
  },
  { accessor: "provider", name: "provider", hasPopup: true, showInitial: true },
  { accessor: "credit_or_debit", name: "Credit/Debit", showInitial: true },
  {
    accessor: "transaction_type",
    name: "Transfer type",
    showInitial: true,
    hasPopup: true,
  },
  {
    accessor: "transfer_type",
    name: "Txn type",
    showInitial: true,
    hasPopup: true,
  },
  { accessor: "amount", name: "amount", showInitial: true },
  {
    accessor: "customer_reference_number",
    name: "Customer ref num",
    showInitial: true,
  },
];

const PaymentsTransactionReactTable = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("All Transactions View", {
        path: window.location.origin + window.location.pathname,
        customer_name: JSON.parse(localStorage.getItem("user")).client_id,
        user_email: JSON.parse(localStorage.getItem("user")).email,
      });
    }
  }, []);
  const {
    isError,
    errorResponse,
    tableData,
    totalCount,
    filterList,
    fetchData,
    firstApiCall,
    searchParameter,
    setSearchParameter,
  } = useContext(PaymentsTransactionContextProvider);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);
  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"All Transactions"}
          provider={PaymentsTransactionContextProvider}
          isModalPresent={false}
          columnList={transactionColumnList}
          showDownloadBtn={false}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default PaymentsTransactionReactTable;
