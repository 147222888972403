// React Imports
import React from "react";
import { unmountComponentAtNode } from "react-dom";
import { useState, useMemo, useEffect } from "react";
// Contexts
import { CallBackContextProvider } from "./CallBackContext";
import CallbackActionContext, {
  CallbackActionProvider,
} from "../../contexts/CallbackActionContext";
// Components
import DateRangePicker from "../DateSelector/DateSelector";
import DataTable from "../Common/components/DataTable/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import { Loader, Button } from "rsuite";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import BaseCell from "../Common/components/BaseCell";
import AddCallback from "./AddCallback/AddCallback";
import SkeletonDatable from "../SkeletonComponents/SkeletonDatable";
// Style imports
import "../Common/ReactDataTable.scss";
import ArrowDown from "../../assets/images/bluedownarrow.svg";
// Utils
import HandleOutsideClick from "../Common/HandleOutsideClick/HandleOutsideClick";
import posthog from "posthog-js";

const EditCell = (props) => {
  const clickFn = props.column?.columnDef?.clickFn;
  return (
    <>
      <a onClick={() => clickFn(props.row.original)}>Edit</a>
    </>
  );
};

const AllCallback = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("Callbacks View", {
        path: window.location.origin + window.location.pathname,
        customer_name: JSON.parse(localStorage.getItem("user")).client_id,
        user_email: JSON.parse(localStorage.getItem("user")).email,
      });
    }
  }, []);

  const viewTransactions = (original) => {
    // unmountComponentAtNode(document.getElementById('table-react'))
    // ReactDOM.render(<ManageBeneficairyActionProvider><ManageBeneficiaryModal data={original} tableEditRow={true} goBackToTable={refreshTable}/></ManageBeneficairyActionProvider>,document.getElementById('table-react'))
  };
  function refreshTable() {
    // unmountComponentAtNode(document.getElementById("table-react"));
    setNoDataFound(false);
    setInitialLoading(true);
    setFilterList({ ...filterList, currentPage: 1 });
    fetchData(1, { ...filterList, currentPage: 1 }, undefined, false);
  }
  let {
    setDateFilters,
    setFilterList,
    setInitialLoading,
    setTableData,
    fetchData,
    setNoDataFound,
    isLazyFetching,
    isLoading,
    tableData,
    filterList,
    totalCount,
    noDataFound,
    initialLoading,
    selectedTransaction,
    dateFilters,
    setLastApiTime,
    lastApiTime,
    lastInterval,
    settotalcountNotset,
    setTotalCount,
    isError,
    errorResponse,
  } = React.useContext(CallBackContextProvider);

  // for switching Cardtype
  const { addNewCallback, cardType, setTableEditData, tableEditData } =
    React.useContext(CallbackActionContext);

  const [sortState, setSortState] = useState({
    sortBy: null,
    sortOrder: null, // true == asc, false === desc, undefined == nosort
  });
  function getSortResult(a, b, key) {
    return a[key].toString().localeCompare(b[key], undefined, {
      numeric: true,
      sensitivity: "base",
    });
  }
  function headerFn(key) {
    setSortState(({ sortBy, sortOrder }) => {
      if (sortBy === key) {
        return {
          sortBy,
          sortOrder:
            sortOrder === null ? false : sortOrder === false ? true : null,
        };
      } else {
        return {
          sortBy: key,
          sortOrder: false,
        };
      }
    });
  }

  const handleEdit = (row) => {
    setTableEditData({ item: row, edit: true });
    addNewCallback();
  };

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isFiltered, setFilter] = useState(false);
  const transactionColumnList = [
    // {
    //   accessor: "company_name",
    //   name: "Company Name",
    //   showInitial: true,
    // },
    {
      accessor: "http",
      name: "Method Type",
      showInitial: true,
    },
    {
      accessor: "callback_type",
      name: "Callback Type",
      showInitial: true,
    },
    {
      accessor: "url",
      name: "Url",
      showInitial: true,
    },
    {
      name: "Action",
      accessor: "",
      showInitial: true,
      // className: "header-small last-child-header",
      // sort: false,
      cell: (row) => (
        <div>
          <button
            onClick={(e) => {
              handleEdit(row.row.original);
            }}
            className="callback-table-edit-btn"
          >
            Edit
            <img src="/images/back.svg" alt="right-arrow" />
          </button>
        </div>
      ),
    },
  ];
  const columnHelper = createColumnHelper();
  const [toggleEntries, setToggleEntries] = useState(false);
  const [toggleColumn, setToggleColumn] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const showCsvModal = () => {
    setShowModal(true);
  };
  function showConsumerId(id, original) {
    setShowPopup(true);
    setSelectedAccount(original);
  }
  const hideCsvModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchData(1, { ...filterList, currentPage: 1 }, undefined, false);
  }, [cardType]);

  const [columnsToShow, setColumnsToShow] = useState(
    transactionColumnList.filter((item) => item.showInitial).map((x) => x.name)
  );
  const columns = useMemo(() => {
    const arr = transactionColumnList;
    return arr
      .filter((x) => columnsToShow.includes(x.name))
      .map((x) =>
        columnHelper.accessor(x?.accessor ?? x.name, {
          cell: x?.cell ?? BaseCell,
          header: x?.header ?? x.name,
          enableSorting: x.enableSorting ?? true,
          clickFn: x.clickFn ?? null,
          filterFn: x.filterFn,
          hasPopup: x.hasPopup,
          headerFn: x.enableSorting !== false ? headerFn : undefined,
          sortState:
            sortState.sortBy === x.accessor
              ? sortState.sortOrder === false
                ? "desc"
                : sortState.sortOrder === true
                ? "asc"
                : undefined
              : undefined,
          ...x,
        })
      );
  }, [columnsToShow, sortState]);
  const initializeCsv = () => {
    const csvHeaders = columnsToShow.join(",");
    const columnData = transactionColumnList;
    const dataTypes = columnData.filter((x) => columnsToShow.includes(x.name));

    const rows = tableData.reverse().map((x) => {
      return dataTypes.map((y) => x[y.accessor]).join(",");
    });
    const csvData =
      "data:text/csv;charset=utf-8," + [csvHeaders, ...rows].join("\n");
    const encodedUri = encodeURI(csvData);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "table-data.csv");
    document.body.appendChild(link);
    link.click();
    hideCsvModal();
  };
  const [filteredOptions, setFilteredOptions] = useState({});

  // Memoizing data
  const { dataToShow, maxPages, startIndex, endIndex, possibleOptions } =
    useMemo(() => {
      const swapValue = (obj) => {
        Object.keys(obj).forEach((key) => {
          if (!obj[key]) {
            obj[key] = "-";
          }
        });
        return obj;
      };
      let data = tableData.map((d) => swapValue(d));

      if (filterList.search !== "") {
        data = data.filter((x) => {
          const string = filterList.search.toLowerCase();
          return JSON.stringify(x).toLowerCase().includes(string);
        });
      }
      let obj = {};
      data.forEach((item) => {
        for (const [itemKey, itemValue] of Object.entries(item)) {
          if (obj[itemKey] !== undefined) {
            obj[itemKey].push(itemValue);
          } else {
            obj[itemKey] = [itemValue];
          }
        }
      });
      Object.keys(obj).forEach((item) => {
        obj[item] = obj[item].filter((a, i) => a && obj[item].indexOf(a) === i);
        if (obj[item]?.length > 30) {
          obj[item] = [];
        }
      });
      const possibleOptions = obj;

      Object.keys(filteredOptions).forEach((item) => {
        if (filteredOptions[item]) {
          data = data.filter(
            (fullItem) => fullItem[item] === filteredOptions[item]
          );
        }
      });
      if (sortState.sortBy !== null && sortState.sortOrder !== null) {
        data = data.sort((a, b) => {
          const key = sortState.sortBy;
          if (sortState.sortOrder === false) {
            return getSortResult(a, b, key) * -1;
          }
          if (sortState.sortOrder === true) {
            return getSortResult(a, b, key);
          }
        });
      }
      const maxPages = Math.ceil(data.length / filterList.resultsPerPage);
      const startIndex =
        (filterList.currentPage - 1) * filterList.resultsPerPage;
      const endIndex = startIndex + filterList.resultsPerPage;

      const paginatedData = data
        ?.reverse()
        .filter((x, i) => i >= startIndex && i < endIndex);

      return {
        dataToShow: paginatedData,
        maxPages,
        startIndex,
        endIndex,
        possibleOptions,
      };
    }, [
      tableData,
      filterList,
      noDataFound,
      isFiltered,
      sortState,
      filteredOptions,
    ]);
  const allColumns = useMemo(() => transactionColumnList, [showTransactions]);
  const toggleEntrie = (e) => {
    if (typeof e === "boolean") {
      setToggleEntries(e);
      return;
    }
    setToggleEntries(!toggleEntries);
  };
  const toggleColum = (e) => {
    if (typeof e === "boolean") {
      setToggleColumn(e);
      return;
    }
    setToggleColumn(!toggleColumn);
  };
  const handleColumnCheck = (name) => {
    if (columnsToShow.includes(name)) {
      const index = columnsToShow.findIndex((x) => x === name);
      setColumnsToShow((prev) => {
        const items = [...prev];
        items.splice(index, 1);
        return [...items];
      });
    } else {
      setColumnsToShow([...columnsToShow, name]);
    }
  };

  function Timer() {
    useEffect(() => {}, [lastApiTime]);
    return (
      <>
        <div className="timer" style={{ fontSize: 11 }}>
          Updated : {lastApiTime} Min ago
        </div>
      </>
    );
  }

  const toggleTransactions = (e) => {
    setFilterList({
      ...filterList,
      currentPage: 1,
      search: "",
    });
    setFilter(false);
    setSortState({
      sortBy: null,
      sortOrder: null, // true == asc, false === desc, undefined == nosort
    });
    setTableData([]);
    setShowTransactions(e);
    setNoDataFound(false);
    if (e) {
      setColumnsToShow(transactionColumnList.map((x) => x.name));
      return;
    }
    setInitialLoading(true);
    setColumnsToShow(transactionColumnList.map((x) => x.name));
  };
  const handleFilter = (key, val) => {
    setFilteredOptions((old) => {
      let newVal = { ...old };
      newVal[key] = val;
      return newVal;
    });
  };

  return (
    <>
      {!isError ? (
        <div className={"ButtonWrapper"}>
          <div style={{ overflowX: "scroll" }}>
            {/* {isLoading && initialLoading ? (
              <SkeletonDatable />
            ) : ( */}
            <>
              <div className="total-count">
                {!showTransactions && (
                  <div className="title">Callback Configuration</div>
                )}
                <button
                  onClick={addNewCallback}
                  className={
                    isLoading ? "add-callback-btn-loading" : `add-callback-btn`
                  }
                  type="button"
                >
                  Add New Callback
                </button>
              </div>
              <div
                className="filter-data-wrapper"
                style={{
                  justifyContent: "flex-start",
                  gap: 20,
                }}
              >
                <div className="search-filter-wrapper">
                  <input
                    type="search"
                    placeholder="Search"
                    value={filterList.search}
                    className="searchbar-input"
                    onChange={(e) => {
                      setNoDataFound(false);
                      if (e.target.value === "" || e.target.value.length > 4) {
                        //   fetchData(1, {
                        //     ...filterList,
                        //     currentPage: 1,
                        //     search: e.target.value,
                        //   });
                      }
                      setFilterList({
                        ...filterList,
                        currentPage: 1,
                        search: e.target.value,
                      });
                    }}
                  />
                </div>

                <div
                  className={
                    isLoading
                      ? "show-entries-wrapper-main-loading"
                      : "show-entries-wrapper-main"
                  }
                >
                  <HandleOutsideClick
                    onClickOutside={() => toggleEntrie(false)}
                  >
                    <div className="entries-wrapper-main">
                      <button onClick={isLoading ? () => {} : toggleEntrie}>
                        {filterList.resultsPerPage} Entries
                        <span>
                          <img
                            src={ArrowDown}
                            alt="Arrow Down"
                            style={
                              isLoading
                                ? {
                                    filter:
                                      "brightness(135%) sepia(100%) hue-rotate(200deg)",
                                  }
                                : {}
                            }
                          />
                        </span>
                      </button>
                      <div
                        onMouseLeave={toggleEntrie}
                        className={toggleEntries ? "block-main" : "hidden"}
                      >
                        <ul>
                          {[10, 20, 30, 50].map((entr) => (
                            <li
                              onClick={() => {
                                setFilterList({
                                  ...filterList,
                                  resultsPerPage: entr,
                                  currentPage: 1,
                                });
                                toggleEntrie();
                              }}
                              key={entr}
                            >
                              {entr}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </HandleOutsideClick>
                </div>
                <div
                  className={
                    isLoading
                      ? "show-column-wrapper-main-loading"
                      : "show-column-wrapper-main"
                  }
                >
                  <HandleOutsideClick onClickOutside={() => toggleColum(false)}>
                    <div className="column-wrapper">
                      <button onClick={isLoading ? () => {} : toggleColum}>
                        Columns
                        <span>
                          <img
                            src={ArrowDown}
                            alt="Arrow Down"
                            style={
                              isLoading
                                ? {
                                    filter:
                                      "brightness(135%) sepia(100%) hue-rotate(200deg)",
                                  }
                                : {}
                            }
                          />
                        </span>
                      </button>
                      <div
                        onMouseLeave={toggleColum}
                        className={toggleColumn ? "block" : "hidden"}
                      >
                        <ul>
                          {allColumns.map((colm) => {
                            return (
                              <li key={colm.name}>
                                <label htmlFor={colm.name}>
                                  {/* <span className="checkboxIcon">
                                    {columnsToShow.includes(colm.name) && (
                                      <span className="checkIcon">âœ”</span>
                                    )}
                                    <input
                                      hidden
                                      onChange={() =>
                                        handleColumnCheck(colm.name)
                                      }
                                      checked={columnsToShow.includes(
                                        colm.name
                                      )}
                                      id={colm.name}
                                      type="checkbox"
                                    />
                                  </span> */}
                                  <span>{colm.name}</span>
                                  <input
                                    type="checkbox"
                                    onChange={() =>
                                      handleColumnCheck(colm.name)
                                    }
                                    checked={columnsToShow.includes(colm.name)}
                                    id={colm.name}
                                  />
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </HandleOutsideClick>
                </div>
                <div
                  className={
                    isLoading
                      ? "download-csv-main-loading"
                      : "download-csv-main"
                  }
                >
                  <button onClick={isLoading ? () => {} : showCsvModal}>
                    Download
                  </button>
                </div>

                {showModal && (
                  <div id="myModal" className="modal" onClick={hideCsvModal}>
                    <div className="modal-content">
                      <div className="modal-header">
                        <p>Download CSV</p>
                        <span className="close"></span>
                      </div>
                      <div className="modal-body">
                        <p className="main_msg">Please note !</p>
                        <p>
                          Only the entries displayed in the table will be
                          downloaded.
                        </p>
                      </div>
                      <div className="button-holder">
                        <button onClick={hideCsvModal} className="cancel">
                          Cancel
                        </button>
                        <button
                          onClick={initializeCsv}
                          className="button_container_csv"
                        >
                          Download Now
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <DataTable
                optionList={possibleOptions}
                filteredOptions={filteredOptions}
                columns={columns}
                setFilter={handleFilter}
                isFiltered={isFiltered}
                data={isLoading ? null : dataToShow}
                enablePagination
                isLoading={isLoading}
                pageIndex={filterList?.currentPage}
                totalMaxPages={Math.ceil(10 / filterList?.resultsPerPage)}
                maxPages={maxPages}
                setPageIndex={(e) => {
                  setFilterList({
                    ...filterList,
                    currentPage: e,
                  });
                }}
              />
            </>
          </div>
        </div>
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

const CallBackTable = () => {
  // for switching Cardtype
  const { cardType } = React.useContext(CallbackActionContext);

  const CALLBACK_CARD = {
    newCallback: <AddCallback />,
    allCallback: <AllCallback />,
  };

  return (
    <div>
      <div>{CALLBACK_CARD[cardType]}</div>
    </div>
  );
};

export default CallBackTable;
