import posthog from "posthog-js";
import React, { useContext, useEffect } from "react";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import TimeCell from "../../utilities/TimeCell";
import ReactDataTable from "../Common/ReactDataTable";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import { CreditReportContextProvider } from "./CreditReportContext";

const transactionColumnList = [
  {
    accessor: "decentro_urn",
    name: "Decentro URN",
    showInitial: true,
  },
  {
    accessor: "response_code",
    name: "Response Code",
    showInitial: true,
  },
  {
    accessor: "timestamp",
    name: "Date-Time",
    cell: TimeCell,
    showInitial: true,
  },
  { accessor: "status", name: "Status", cell: StatusCell, showInitial: true },
  {
    accessor: "message",
    name: "Response Message",

    showInitial: true,
  },
  {
    accessor: "company_urn",
    name: "Customer Reference ID",
    showInitial: true,
  },
];

const FinancialServicesCreditReportTable = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("FS - Credit Report View", {
        path: window.location.origin + window.location.pathname,
        customer_name: JSON.parse(localStorage.getItem("user")).client_id,
        user_email: JSON.parse(localStorage.getItem("user")).email,
      });
    }
  }, []);
  const {
    errorResponse,
    isError,
    tableData,
    totalCount,
    filterList,
    fetchData,
    firstApiCall,
  } = useContext(CreditReportContextProvider);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"Credit Report"}
          columnList={transactionColumnList}
          isModalPresent={false}
          provider={CreditReportContextProvider}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default FinancialServicesCreditReportTable;
