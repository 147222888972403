import posthog from "posthog-js";
import React, { useEffect } from "react";
import apiEndpointList from "../../config/core_banking/endpoint";
import { APIConfig } from "../../services/apiConfiguration.js";
import Loader from "../Loader/Loader";
import "./KycAnalytics.scss";

const KycAnalytics = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("KYC - Analytics View", {
        path: window.location.origin + window.location.pathname,
        customer_name: JSON.parse(localStorage.getItem("user")).client_id,
        user_email: JSON.parse(localStorage.getItem("user")).email,
      });
    }
  }, []);

  let [graphData, setGraphData] = React.useState({
    usable_credits: 0,
    used_credits: 0,
  });
  let initialStartDate = new Date();
  let [isLoading, setisLoading] = React.useState(true);
  initialStartDate.setDate(initialStartDate.getDate() - 10);
  let [selectedDateRange, setSelectedDateRange] = React.useState([
    initialStartDate,
    new Date(),
  ]);
  //   let selectedDateRangeForLogs = [selectedDateRange[0], selectedDateRange[1]];
  const getAccountData = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_CREDITS.baseUrl +
        apiEndpointList.FETCH_CREDITS.endpoint,
      { module_name: "kyc-and-onboarding" }
    )
      .then((response) => {
        setisLoading(false);
        if (response.data.length === 0) {
          graphData = null;
        } else {
          graphData = response.data[0];
        }
        setGraphData(graphData);
      })
      .catch(() => {
        setisLoading(false);
      });
  };
  useEffect(() => {
    getAccountData();
  }, []);
  // CREDITS BAR FOR ANALYTICS
  // const CreditsBar = (params) => {
  //   return (
  //     <div className="credits-summary-row">
  //       <div className="for-all">
  //         <div className="left">
  //           <div className="summary-content divider">
  //             <label>Main Credit</label>
  //             <h3>&#8377; 236</h3>
  //           </div>
  //           <div className="summary-content divider">
  //             <label>Main Debit</label>
  //             <h3>&#8377; 154</h3>
  //           </div>
  //           <div className="summary-content divider">
  //             <label>Commission Available</label>
  //             <h3>&#8377; 2123</h3>
  //           </div>
  //           <div className="summary-content">
  //             <label>Commission Pending</label>
  //             <h3>&#8377; 1213</h3>
  //           </div>
  //         </div>
  //         <div className="right">
  //           <div className="summary-content">
  //             <label>Virtual Balance</label>
  //             <ErrorIcon className="error-icon" />
  //             <h3 id="right-summary_value">&#8377; 220</h3>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  return (
    <React.Fragment>
      {/* <CreditsBar /> */}
      <div id="loading"></div>
      <div className="virtual-accounts-api-graphs">
        <div className="row-two">
          <div className="credits-utilised-graph graph">
            <div className="head">
              <h3>Available Credits</h3>
            </div>
            {isLoading ? (
              <Loader />
            ) : graphData !== null ? (
              graphData?.usable_credits === 0 &&
              graphData?.used_credits === 0 ? (
                <div>No Credits Available</div>
              ) : (
                <div className="chart">
                  <h1 className="available-credits-num">
                    {(graphData?.usable_credits - graphData?.used_credits)
                      .toFixed(1)
                      .toLocaleString("en-IN")}
                  </h1>
                  {/* <CreditsUtilisedGraph data={graphData} /> */}
                </div>
              )
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                No Credits Available
              </div>
            )}
          </div>
          <div className="text-block">
            <div className="text-container">
              <h3>Documentation</h3>
              <p>
                Here you can find APIs around creation and management of bank
                accounts along with seamless and inter-operable money transfers
                with automated reconciliation. This includes but is not limited
                to virtual/savings/business account creation, updation,
                management, money flow, collections, bank statement analysis and
                much more.
              </p>
            </div>
            <a
              href={
                "https://docs.decentro.tech/docs/kyc-overview-and-guide" +
                encodeURI(
                  "?utm_source=Dashboard&utm_medium=KYC+Section&utm_campaign=Dashboard+KYC+Section"
                )
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              View Documentation
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default KycAnalytics;
