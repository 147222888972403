import React, { useState, useContext, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { modulesContext } from "../../contexts/modulesContext";
import apiEndpointList from "../../config/core_banking/endpoint";
import { APIConfig } from "../../services/apiConfigurationOtp";
// Component Imports
import InfoCarousel from "../UI/InfoCarousel/InfoCarousel";
// Style imports
import "./SubmenuBar.scss";

const SubmenuBar = () => {
  const { currentModuleData } = useContext(modulesContext);
  const location = useLocation();
  const [submenuCollapse, setSubmenuCollapse] = useState(false);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState("");
  const history = useHistory();
  function changeDropdown(item) {
    if (item.submenus?.length < 1) {
      history.push(item.path);
      setSelectedDropdownItem("");
    } else {
      return; // for dropdown
      if (item.display_name === selectedDropdownItem) {
        setSelectedDropdownItem("");
        return;
      }
      setSelectedDropdownItem(item.display_name);
    }
  }

  const activePath = useMemo(() => location.pathname, [location.pathname]);

  const slides = [
    {
      heading: "CAS",
      text: "It stands for Consolidated Account Statement with all the financial transactions during a defined period. This report is available to download at a provider level.",
    },
    {
      heading: "Payouts",
      text: "It means paying or disbursing an amount to a customer. This report is available to download at a provider level.",
    },
    {
      heading: "Collection Request",
      text: "A request done by the client to collect money based on the agreed format. This report is available to download at a provider level.",
    },
    {
      heading: "Payment Links",
      text: "A URL that allows the customers to make a transaction online by redirecting to the checkout page.",
    },
    {
      heading: "Credit Consumption",
      text: "Credits are deducted for accessing the APIs at Decentro as per the agreement. This report is available to download at a Module level.",
    },
    {
      heading: "Beneficiaries",
      text: "A beneficiary is the person you're sending money to - also known as a recipient. A beneficiary can be a person or a business entity.",
    },
    {
      heading: "eMandate",
      text: "It stands for Electronic Mandate. It works as a standing instruction that allows banks to debit the specified amount from your bank account.",
    },
    {
      heading: "Virtual Accounts",
      text: "It is a digital payment method that is created virtually for each customer. A Virtual Account consists of a unique customer ID number.",
    },
  ];

  return (
    <div
      className={
        // submenuCollapse
        //   ? "submenu-wrapper collapsed-submenu"
        //   : "submenu-wrapper extended-submenu"
        "submenu-wrapper extended-submenu"
      }
    >
      <div className="submenu-bar">
        <div>
          <ul className="nav-primary">
            {currentModuleData !== undefined &&
              currentModuleData !== null &&
              currentModuleData !== undefined &&
              currentModuleData !== null &&
              currentModuleData.submenus !== undefined &&
              currentModuleData.submenus.map((item, index) => {
                return (
                  <li
                    className="grid"
                    key={index}
                    // onMouseOver={() => {
                    //   if (submenuCollapse == true) setSubmenuCollapse(false);
                    // }}
                    // onMouseLeave={() => {
                    //   setSelectedDropdownItem("");
                    //   if (submenuCollapse == false) setSubmenuCollapse(true);
                    // }}
                  >
                    <div
                      className={`nav-link ${
                        ((item.submenus.length > 0 &&
                          activePath.includes(item.module)) ||
                          activePath.includes(item.path)) &&
                        "active"
                      }`}
                      onClick={() => changeDropdown(item)}
                    >
                      {(item?.submenus?.length < 1 || submenuCollapse) && (
                        <img
                          className={`module-icon ${
                            item?.submenus?.length > 0 && "no-color"
                          }`}
                          src={item.icon_path}
                          alt="Link Icon"
                        />
                      )}
                      <span
                        className={`display-name ${
                          item?.submenus?.length > 0 && "no-color"
                        }`}
                      >
                        {item.display_name}
                      </span>
                      {/* {item.submenus?.length > 0 && (
                        <div
                          className={`dropdown-icon  ${
                            selectedDropdownItem === item.display_name
                              ? "dropdown-closed"
                              : "dropdown-open"
                          }
                      `}
                        >
                          <img
                            width="18"
                            alt="back-icon"
                            src="/images/back.svg"
                          />
                        </div>
                      )} */}
                    </div>
                    {item.submenus?.length > 0 && (
                      // && !submenuCollapse
                      <ul
                        className={`nav-dropdown-elements-container ${"active"}`}
                      >
                        {item.submenus.map((subItem) => (
                          <li>
                            <NavLink
                              to={subItem.path}
                              className="sub-item"
                              activeClassName="active"
                            >
                              <img
                                className="sub-module-icon"
                                src={subItem.icon_path}
                                alt="Link Icon"
                                width="12"
                              />
                              <span className="display-name">
                                {subItem.display_name}
                              </span>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
        {/* To display info on the bottom of submenubar for reports section */}
        {location.pathname == "/dashboard/reports/generate-reports" && (
          <div className="info-section-wrapper">
            <div className="info-section-top">
              <div className="info-section-header">
                <h3 className="update-section-header">Latest Update</h3>
                <img src="/images/magic.svg" alt="magic-icon" />
              </div>
              <p>
                You can generate all reports for data upto{" "}
                <strong>365 days</strong> from the present date.
              </p>
            </div>
            <hr />
            <div className="info-section-scrollable">
              <InfoCarousel
                slides={slides}
                infiniteLoop={true}
                showIndicators={false}
                showThumbs={true}
                emulateTouch={true}
                autoPlay={true}
                showArrows={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubmenuBar;
