import posthog from "posthog-js";
import React, { useContext, useEffect } from "react";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import TimeCell from "../../utilities/TimeCell";
import ReactDataTable from "../Common/ReactDataTable";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import "./IdClassification.scss";
import { IdClassificationContextProvider } from "./IdClassificationContext";

const transactionColumnList = [
  {
    accessor: "decentro_urn",
    name: "Decentro URN",
    showInitial: true,
  },
  {
    accessor: "response_code",
    name: "Response Code",
    showInitial: true,
  },
  {
    accessor: "time_stamp",
    name: "Date-Time",
    cell: TimeCell,
    showInitial: true,
  },
  {
    accessor: "api_status",
    name: "API Status",
    showInitial: true,
    cell: StatusCell,
  },
  { accessor: "response_message", name: "Response Message", showInitial: true },
  // { accessor: "document_type", name: "Document type" },
  {
    accessor: "customer_reference_id",
    name: "Customer Ref ID",
    showInitial: true,
  },
];

const IdClassificationReact = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("KYC - ID Classification View", {
        path: window.location.origin + window.location.pathname,
        customer_name: JSON.parse(localStorage.getItem("user")).client_id,
        user_email: JSON.parse(localStorage.getItem("user")).email,
      });
    }
  }, []);
  const {
    isError,
    errorResponse,
    tableData,
    totalCount,
    fetchData,
    filterList,
    firstApiCall,
  } = useContext(IdClassificationContextProvider);
  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);
  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"ID Classification"}
          provider={IdClassificationContextProvider}
          columnList={transactionColumnList}
          isModalPresent={false}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default IdClassificationReact;
